.FilterMenuWrapper {
  position: relative;

  .FilterMenuContainer {
    display: flex;
    flex-direction: column;
    position: absolute;
    top: calc(100% + 10px);
    right: 0;
    padding: 20px;
    width: 360px;
    background-color: #3d3f3f;
    z-index: 100000;
    border-radius: 6px;
    border: solid 1px white;
    max-width: calc(100vw - 72px);
  }
}
