@use 'colors';

.ConfirmDialog_container {
  position: fixed;
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.324);
  z-index: 200;

  .ConfirmDialog {
    width: 300px;
    height: auto;
    border-radius: 10px;
    border: solid 1px lightgray;
    padding: 20px;
    background-color: colors.$primary-black;

    .ConfirmDialog_title {
      font-weight: 600;
      margin-bottom: 20px;
      margin-top: 0;
    }
  }
}