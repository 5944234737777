@use 'colors';

.QuestionsContainer{
  position: relative;
  width: 100%;
  height: 100%;
  // This after element hides corner scroll block that is side effect
  // of styling the list scrollbar
  &::after {
    content: '';
    position: absolute;
    width: 11px;
    height: 11px;
    right: 40px;
    bottom: 0px;
    background-color: colors.$primary-black;
  }

  h1 {
    margin: 0;
    padding: 20px 20px 0 80px;
    font-size: 22px;
  }

  .QuestionsContainer_questionList {
    display: grid;
    max-width: calc(100vw - 120px);
    position: relative;
    border-radius: 5px;
    margin-left: 80px;
    overflow: auto;
    max-height: calc(100vh - 120px);

    &::-webkit-scrollbar {
      appearance: none;
      -webkit-appearance: none;
    }

    &::-webkit-scrollbar:vertical {
      width: 11px;
    }

    &::-webkit-scrollbar:horizontal {
      height: 11px;
    }
    
    &::-webkit-scrollbar-thumb {
      border-radius: 8px;
      border: solid 2px colors.$primary-black;
      background: lightgray;
    }

    &::-webkit-scrollbar-track {
      background-color: colors.$primary-black;  
    }

    thead {
      display: grid;
      position: sticky;
      top: 0;
      z-index: 1;

      tr {
        display: flex;
        width: 100%;
        height: 40px;

        th {
          display: flex;
          background-color: #3d3f3f;
          border-top: solid 1px white;
          position: relative;

          &:first-child {
            border-top-left-radius: 5px;
            border-left: solid 1px white;
          }
  
          &:last-child {
            border-top-right-radius: 5px;
            // border-right: solid 1px white;

            .columnDragArea.right {
              border-top-right-radius: 5px;
            }
          }

          .columnDragArea {
            position: absolute;
            width: 8px;
            height: 40px;
            cursor: col-resize;
            top: 0;

            &.left {
              left: 0px;
            }

            &.right {
              right: 0px;
              border-right: solid 1px white;
            }
          }
        }
      }
    }

    tbody {
      display: grid;
      position: relative;

      tr:last-child {
        td:first-child {
          border-bottom-left-radius: 5px;
        }

        td:last-child {
          border-bottom-right-radius: 5px;
        }
      }

      td.titleCell {
        position: relative;

        .titleCell_editButtonWrapper {
          display: none;
          justify-content: flex-end;
          background-image: linear-gradient(to right, transparent , #414141);
          background-color: transparent;
          border: none;
          width: 100px;
          height: 40px;
          position: absolute;
          right: 0;
          top: 0;

          button {
            border: none;
            background: none;
            display: flex;
            justify-content: center;
            align-items: center;
            width: auto;
            svg {
              width: 24px;
              height: 24px;
              cursor: pointer;
              fill: white;
            }
          }
        }
      }

      td.tagsCell {
        display: flex;
        flex-wrap: nowrap;
        align-items: center;
        gap: 4px;

        .tagsCell_tag {
          display: block;
          padding: 4px 6px;
          border-radius: 10px;
          font-size: 13px;
          background-color: #eeeeee;
          color: colors.$primary-black;
        }
      }

      tr {
        display: flex;
        position: absolute;
        width: 100%;
        &:hover {
          .titleCell_editButtonWrapper {
            display: flex;
          }
        }
      }
    }

    tr {
      box-sizing: border-box;
      background-color: colors.$primary-black;

      &:hover {
        td {
          background-color: #676767;
        }
      }

      td:last-child {
        border-right: solid 1px white;
      }

      td, th {
        border-bottom: solid 1px white;
        display: flex;
        white-space: nowrap;
        overflow: hidden;
        align-items: center;
        // border-left: solid 1px white;
        padding-left: 8px;
        box-sizing: border-box;
      }

      td {
        border-left: solid 1px white;
      }
    }
  }
}

.QuestsionsToolbarContainer{
  display: flex;
  padding: 30px 40px 30px 80px;
  align-items: center;

  .QuestsionsToolbarContainer_searchWrapper{
    display: flex;
    margin-left: auto;
    gap: 4px;
    margin-right: 20px;

    .TextInput_container {
      margin-bottom: 0;
    }

    button {
      background: none;
      border: none;
      color: colors.$primary-white;
      font-size: 11px;
      text-wrap: wrap;
      width: 50px;
      padding: 0;
      text-decoration: underline;
      text-align: left;
      cursor: pointer;
    }
  }
}

//  Question Modal

.EditQuestionModal {
  max-width: 500px;
}