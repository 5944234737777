@use 'colors';

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: colors.$primary-black;
  color: colors.$primary-white;
  font-family: roboto;
}

input, textarea {
  font-family: roboto;
  font-size: 14px;
  border-radius: 5px;
  border: none;

  &:focus-visible {
    outline: #7089ff solid 2px;
  }
}

/* Modal */

.ModalContainer {
  position: fixed;
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.324);
  z-index: 100;

  .modal {
    width: 80%;
    height: auto;
    background-color: whitesmoke;
    border-radius: 10px;
    border: solid 1px lightgray;
    padding: 20px;
    background-color: colors.$primary-black;
    margin-bottom: auto;
    margin-top: 10%;
    position: relative;

    h2 {
      margin-top: 0;
    }
  }
}

/* Loader */

.Container_loader {
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  left: 0;
  top: 0;
  background-color: rgba(0, 0, 0, 0.175);
  z-index: 10000000;

  .Container_loaderSpinner {
    border: 5px solid #f3f3f3;
    border-radius: 50%;
    border-top: 5px solid #385F71;
    width: 50px;
    height: 50px;
    -webkit-animation: spin 2s linear infinite; /* Safari */
    animation: spin 2s linear infinite;
  }
}

@-webkit-keyframes spin {
  0% { -webkit-transform: rotate(0deg); }
  100% { -webkit-transform: rotate(360deg); }
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

/* Buttons */

.Button {
  border-radius: 5px;
  background: none;
  font-family: Roboto;
  cursor: pointer;
  padding: 6px 10px;
  position: relative;

  &:disabled {
    opacity: 0.5;
    cursor: default;
  }

  &.primary {
    background-color: #385F71;
    border: solid 1px white;
    color: colors.$primary-white;
  }

  &.cancel {
    border: solid 1px white;
    background-color: lightgray;
  }

  &.delete {
    border: solid 1px white;
    background-color: rgb(198, 38, 38);
    color: white;
  }
}

.Button_footer {
  display: flex;
  gap: 10px;
  justify-content: flex-end;
  margin-top: 20px;
}

/* Inputs */

.TextInput_container {
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;
  .TextInput_label {
    margin-bottom: 4px;
    font-weight: 600;
  }

  .TextInput_input {
    padding: 6px 10px;
  }
}

/* Textarea */

.TextArea_containter {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-bottom: 10px;
  resize: none;

  .TextArea_label {
    margin-bottom: 4px;
    font-weight: 600;
  }

  .TextArea_textarea {
    padding: 6px 10px;
    resize: none;
    max-height: 150px;
    min-height: 45px;
    box-sizing: border-box;
  }
}

/* Radio inputs */

.RadioInput_container {
  display: block;
  position: relative;
  padding-left: 24px;
  // margin-bottom: 12px;
  cursor: pointer;
  font-size: 16px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

  &:hover {
    .RadioInput_dot {
      background-color: #ccc;
    }
  }

  .RadioInput_input {
    position: absolute;
    opacity: 0;
    cursor: pointer;

    &:checked ~ .RadioInput_dot{
      background-color: #385F71;

      &::after {
        display: block;
      }
    }
  }

  .RadioInput_dot {
    position: absolute;
    top: 0;
    left: 0;
    height: 16px;
    width: 16px;
    background-color: #eee;
    border: solid 1px white;
    border-radius: 50%;

    &::after {
      content: "";
      position: absolute;
      display: none;
      top: 4px;
	    left: 4px;
	    width: 8px;
	    height: 8px;
	    border-radius: 50%;
	    background: white;
    }
  }
}

// Coming soon styles
.ComingSoon {
  opacity: 0.7;
  position: relative;

  input, div, span {
    pointer-events: none;
  }

  &::after {
    content: 'Coming soon';
    display: none;
    position: absolute;
    margin-left: auto;
    margin-right: auto;
    color: white;
    background-color: black;
    padding: 4px;
    font-size: 12px;
    border-radius: 4px;
    left: 0;
    right: 0;
    width: 72px;
    top: 50%;
    transform: translateY(-50%);
  }

  &:hover::after {
    display: block;
  }
}

@media screen and (max-width: 430px) {
  .ModalContainer {
    margin-top: 10px;
  }
}