.NavMenuContainer {
  position: fixed;
  height: 100vh;
  width: 44px;
  background-color: #3d3f3f;
  left: 0;
  top: 0;
  z-index: 10000;
  transition: width 0.3s;
  border-right: solid 1px #757575;
  overflow: hidden;

  &:hover {
    width: 300px;

    .NavMenu_header{
      span {
        opacity: 1;
      }

      svg {
        opacity: 0;
      }
    } 
  }

  .NavMenu_header {
    white-space: nowrap;

    .NavMenu_headerTitle {
      font-weight: 600;
      font-size: 22px;
      opacity: 0;
      transition: opacity 0.3s;
      white-space: nowrap;
      position: relative;
      overflow: hidden;
      text-overflow: ellipsis;
      width: 280px;
      display: block;
      padding: 12px;
      border-bottom: solid 1px white;
    }

    svg {
      width: 24px;
      height: 24px;
      position: absolute;
      left: 11px;
      top: 12px;
      opacity: 1;
      transition: opacity 0.3s;
    }
  }

  .NavMenu_list {
    width: 300px;
    height: calc(100% - 51px);
    display: flex;
    flex-direction: column;

    .NavMenu_listLink {
      white-space: nowrap;
      display: flex;
      align-items: center;
      padding: 15px 10px;
      color: white;
      gap: 10px;
      font-size: 18px;
      text-decoration: none;
      background-color: transparent;
      border: none;
      cursor: pointer;

      &.selected {
        background-color: #5e5e5e;
      }

      svg {
        fill: white;
        width: 24px;
        height: 24px;
      }
    }

    
  }
}

@media screen and (max-width: 430px) {
  .NavMenuContainer {
    height: 44px;
    width: 100%;
    transition: height 0.3s;

    &:hover {
      height: 44px;
      width: 100%;

      .NavMenu_header{
        span {
          opacity: 0;
        }
  
        svg {
          opacity: 1;
        }
      } 
    }

    &.expanded {
      width: 100%;
      height: 330px;
  
      .NavMenu_header{
        span {
          opacity: 1;
          width: 100%;
        }
  
        svg {
          opacity: 0;
        }
      } 
    }
  }
}