.QuizzesContainer{
  position: relative;
  width: 100%;
  height: 100%;

  .Quizzes_activeQuizBanner {
    margin: 20px 20px 0 80px;
    padding: 10px;
    border: solid 1px burlywood;
    background-color: rgba(222, 184, 135, 0.276);
    border-radius: 10px;
    max-width: 400px;
    display: flex;
    align-items: center;

    .Button {
      margin-left: auto;
      display: block;
    }
  }
}

//  Quiz Modal

.EditQuizModal {
  max-width: 500px;

  .EditQuizModal_availableQuestions {
    display: flex;
    align-items: center;
    gap: 10px;
    margin: 20px 0;
    font-size: 18px;

    .EditQuizModal_availableQuestionsTitle {
      font-size: 18px;
      font-weight: 600;
    }
  }

  .EditQuizModal_questionNumContainer {
    display: flex;
    flex-direction: column;
    gap: 4px;

    .EditQuizModal_questionNumTitle {
      font-weight: 600;
    }

    .EditQuizModal_questionNumSelector {
      display: flex;
      align-items: center;
      gap: 10px;

      .RadioInput_container:first-child{
        margin-right: 10px;
      }

      .TextInput_container {
        margin-bottom: 0;

        .TextInput_input {
          width: 50px;
        }
      }
    }
  }
}