@use 'colors';

.Table_rowList {
  display: grid;
  max-width: calc(100vw - 120px);
  position: relative;
  border-radius: 5px;
  margin-left: 80px;
  overflow: auto;
  max-height: calc(100vh - 120px);

  &::-webkit-scrollbar {
    appearance: none;
    -webkit-appearance: none;
  }

  &::-webkit-scrollbar:vertical {
    width: 11px;
  }

  &::-webkit-scrollbar:horizontal {
    height: 11px;
  }
  
  &::-webkit-scrollbar-thumb {
    border-radius: 8px;
    border: solid 2px colors.$primary-black;
    background: lightgray;
  }

  &::-webkit-scrollbar-track {
    background-color: colors.$primary-black;  
  }

  thead {
    display: grid;
    position: sticky;
    top: 0;
    z-index: 1;

    tr {
      display: flex;
      width: 100%;
      height: 40px;

      th {
        display: flex;
        background-color: #3d3f3f;
        border-top: solid 1px white;
        position: relative;

        &:first-child {
          border-top-left-radius: 5px;
          border-left: solid 1px white;
        }

        &:last-child {
          border-top-right-radius: 5px;
          // border-right: solid 1px white;

          .columnDragArea.right {
            border-top-right-radius: 5px;
          }
        }

        .columnDragArea {
          position: absolute;
          width: 8px;
          height: 40px;
          cursor: col-resize;
          top: 0;

          &.left {
            left: 0px;
          }

          &.right {
            right: 0px;
            border-right: solid 1px white;
          }
        }
      }
    }
  }

  tbody {
    display: grid;
    position: relative;

    tr:last-child {
      td:first-child {
        border-bottom-left-radius: 5px;
      }

      td:last-child {
        border-bottom-right-radius: 5px;
      }
    }

    td.titleCell {
      position: relative;

      .titleCell_editButtonWrapper {
        display: none;
        justify-content: flex-end;
        background-image: linear-gradient(to right, transparent , #414141);
        background-color: transparent;
        border: none;
        width: 100px;
        height: 40px;
        position: absolute;
        right: 0;
        top: 0;

        button {
          border: none;
          background: none;
          display: flex;
          justify-content: center;
          align-items: center;
          width: auto;
          svg {
            width: 24px;
            height: 24px;
            cursor: pointer;
            fill: white;
          }
        }
      }
    }

    td.tagsCell {
      display: flex;
      flex-wrap: nowrap;
      align-items: center;
      gap: 4px;

      .tagsCell_tag {
        display: block;
        padding: 4px 6px;
        border-radius: 10px;
        font-size: 13px;
        background-color: #eeeeee;
        color: colors.$primary-black;
      }
    }

    tr {
      display: flex;
      position: absolute;
      width: 100%;
      &:hover {
        .titleCell_editButtonWrapper {
          display: flex;
        }
      }
    }
  }

  tr {
    box-sizing: border-box;
    background-color: colors.$primary-black;

    &:hover {
      td {
        background-color: #676767;
      }
    }

    td:last-child {
      border-right: solid 1px white;
    }

    td, th {
      border-bottom: solid 1px white;
      display: flex;
      white-space: nowrap;
      overflow: hidden;
      align-items: center;
      // border-left: solid 1px white;
      padding-left: 8px;
      box-sizing: border-box;
    }

    td {
      border-left: solid 1px white;
    }
  }
}

@media screen and (max-width: 430px) {
  .Table_rowList {
    display: block;
    width: 100%;
    max-width: 100%;
    margin-left: 0;
    padding: 0 15px;

    &::-webkit-scrollbar {
      display: none;
    }

    &.Quizzes {
      thead tr::before {
        content: 'Quizzes';
      }
    }

    &.Questions {
      thead tr::before {
        content: 'Questions';
      }
    }

    thead {
      display: block;
      width: 100%;
      margin-bottom: 6px;

      tr{
        background-color: #3d3f3f;

        th {
          display: none;
        }

        &::before {
          font-weight: 600;
          margin-left: 6px;
          display: flex;
          align-items: center;
        }
      }
    }

    tbody {
      display: flex;
      flex-direction: column;

      tr {
        padding: 5px;
        border: solid 1px white;
        height: 280px;
        border-bottom: none;
        padding-bottom: 10px;
        border-radius: 6px;
        display: flex;
        flex-direction: column;

        &:hover {
          td {
            background-color: transparent;
          }

          .titleCell_editButtonWrapper {
            display: none;
          }
        }

        &::before {
          content: '';
          display: block;
          position: absolute;
          width: calc(100% + 2px);
          height: 20px;
          background-color: #4C5454;
          left: -1px;
          bottom: 0;
        }

        &::after {
          content: '';
          display: block;
          position: absolute;
          width: 100%;
          height: 10px;
          background-color: #4C5454;
          border-bottom: solid 1px white;
          border-left: solid 1px white;
          border-right: solid 1px white;
          left: -1px;
          bottom: 10px;
          border-bottom-left-radius: 6px;
          border-bottom-right-radius: 6px;
        }

        td {
          border-left: none;
          border-bottom: none;
          width: 100%;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          display: block;
          
          &:last-child {
            border-right: none;
          }

          &.titleCell {
            position: unset;

            .titleCell_editButtonWrapper {
              display: block;
              background-image: none;
              position: absolute;
              top: 0;
              left: 0;
              width: 100%;
              height: calc(100% - 10px);

              button {
                display: block;
                opacity: 0;
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                cursor: pointer;
              }
            }
          }

          .mobileCellLabel {
            font-weight: 600;
            margin-right: 4px;
          }
        }
      }
    }
  }
}