@use 'colors';

.ActiveQuizContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  width: 100vw;
  height: 100vh;

  .ActiveQuiz_exitQuizButton {
    position: absolute;
    right: 30px;
    top: 30px;

    svg {
      width: 28px;
      height: 28px;
      cursor: pointer;
      fill: white;
    }
  }

  .ActiveQuiz_questionContainer {
    border: solid 1px white;
    border-radius: 10px;
    width: 600px;
    height: 450px;
    padding: 20px;

    .ActiveQuiz_questionTextSection {
      width: 100%;
      height: 50%;
      border-bottom: solid 1px white;

      .ActiveQuiz_questionCounter {
        width: 100%;
        font-size: 16px;
        font-weight: 600;
      }

      .ActiveQuiz_questionText {
        margin-top: 20px;
      }
    }

    .ActiveQuiz_questionAnswerSection {
      width: 100%;
      height: 50%;
      position: relative;
      padding-bottom: 70px;
      box-sizing: border-box;

      .ActiveQuiz_questionAnswerTitle {
        margin-top: 20px;
        font-weight: 600;
      }

      .ActiveQuiz_questionAnswer {
        margin-top: 20px;
      }

      .nextQuestionButton {
        position: absolute;
        right: 0;
        left: 0;
        margin: auto;
        width: 300px;
        bottom: 20px;
      }

      .showAnswerButton {
        position: absolute;
        right: 0;
        left: 0;
        margin: auto;
        top: 50%;
        -ms-transform: translateY(-50%);
        transform: translateY(-50%);
        width: 300px;
      }
    }
  }
}