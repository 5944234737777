@use 'colors';

.ToolbarContainer{
  display: flex;
  padding: 30px 40px 45px 80px;
  align-items: center;
  position: relative;

  .ToolbarContainer_questionsCount {
    font-size: 13px;
    margin-left: 10px;
    position: absolute;
    bottom: 10px;
    left: 70px;
  }

  .ToolbarContainer_searchWrapper{
    display: flex;
    margin-left: auto;
    gap: 4px;
    margin-right: 20px;

    .TextInput_container {
      margin-bottom: 0;
    }

    button {
      background: none;
      border: none;
      color: colors.$primary-white;
      font-size: 11px;
      text-wrap: wrap;
      width: 50px;
      padding: 0;
      text-decoration: underline;
      text-align: left;
      cursor: pointer;
    }
  }

  .ToolbarContainer_filterButton {
    display: flex;
    align-items: center;
    gap: 6px;

    .ToolbarContainer_filterCounter{
      display: flex;
      height: 14px;
      width: 14px;
      border-radius: 50%;
      color: white;
      background-color: #3d3f3f;
      border: solid 1px white;
      justify-content: center;
      align-items: center;
      font-size: 12px;
    }
  }
}

@media screen and (max-width: 430px) {
  .ToolbarContainer {
    padding-top: 65px;
    padding-left: 15px;
    padding-right: 15px;
    padding-bottom: 20px;

    &.Questions {
      padding-bottom: 35px;
    }

    .ToolbarContainer_questionsCount {
      left: 15px;
      margin-left: 0;
    }

    .ToolbarContainer_searchWrapper {
      margin-right: auto;
      margin-left: auto;
    }

    .TextInput_container{
      width: 140px;
    }
  }
}