.WorkspacesContainer {
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  
  .Workspaces_title {
    font-weight: 600;
  }
  
  .Workspaces_workspaceList{
    display: flex;
    flex-direction: column;
    max-width: 500px;
    border: solid 1px white;
    padding: 20px;
    border-radius: 10px;
    gap: 5px;
    margin: 10px 0 20px;

    a {
      color: white;
    }
  }
}

.EditWorkspaceModal{
  padding: 30px;
  max-width: 360px;
}