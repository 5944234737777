@use 'colors';

.Select_containter {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-bottom: 10px;

  .Select_label {
    margin-bottom: 4px;
    font-weight: 600;
  }

  .react-select__placeholder {
    font-family: roboto;
    font-size: 14px;
  }

  .react-select__control {
    border: none;
    border-radius: 5px;
  }

  .react-select__menu-list {
    color: colors.$primary-black;
    max-height: 250px;
  }
}